import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminHouseInfo from "./AdminHouseInfo";
import UserHouseInfo from "./UserHouseInfo";
import {
  loadHouseInfo,
  createHouseInfo,
  deleteHouseInfo,
  editHouseInfo,
} from "../../actions/houseInfo";

const HouseInfoContainer = () => {
  const login = useSelector((state) => state.login);
  const houseInfo = useSelector((state) => state.houseInfo);

  const [newHouseInfo, setNewHouseInfo] = useState({
    question: "",
    answer: "",
    sortOrder: 1,
  });
  const [houseInfoToEdit, setHouseInfoToEdit] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadHouseInfo());
  }, [dispatch]);

  const newHouseInfoOnChange = (event) => {
    setNewHouseInfo({
      ...newHouseInfo,
      [event.target.name]: event.target.value,
    });
  };

  const saveNewHouseInfo = (event) => {
    event.preventDefault();
    dispatch(createHouseInfo(newHouseInfo));
    setNewHouseInfo({
      question: "",
      answer: "",
      sortOrder: 1,
    });
  };

  const deleteHouseInfoOnClick = (event, id) => {
    event.preventDefault();
    dispatch(deleteHouseInfo(id));
  };

  const editHouseInfoOnClick = (event, houseInfoToEdit) => {
    event.preventDefault();
    setHouseInfoToEdit(houseInfoToEdit);
  };

  const editHouseInfoOnChange = (event) => {
    setHouseInfoToEdit({
      ...houseInfoToEdit,
      [event.target.name]: event.target.value,
    });
  };

  const saveEditedHouseInfo = (event) => {
    event.preventDefault();
    dispatch(editHouseInfo(houseInfoToEdit));
    setHouseInfoToEdit(null);
  };

  const cancelEditingHouseInfo = (event) => {
    event.preventDefault();
    setHouseInfoToEdit(null);
  };

  return (
    <div className="houseInfoContainer">
      <p className="calendar_text mb-5">Mi hogy működik a házban?</p>

      {login && login.role === "user" && (
        <UserHouseInfo houseInfo={houseInfo} />
      )}

      {login && login.role === "admin" && (
        <AdminHouseInfo
          houseInfo={houseInfo}
          newHouseInfo={newHouseInfo}
          onSubmit={saveNewHouseInfo}
          onChange={newHouseInfoOnChange}
          deleteHouseInfoOnClick={deleteHouseInfoOnClick}
          editHouseInfoOnClick={editHouseInfoOnClick}
          houseInfoToEdit={houseInfoToEdit}
          editHouseInfoOnChange={editHouseInfoOnChange}
          saveEditedHouseInfo={saveEditedHouseInfo}
          cancelEditingHouseInfo={cancelEditingHouseInfo}
        />
      )}

      <div className="bottomGap"></div>
    </div>
  );
};

export default HouseInfoContainer;
