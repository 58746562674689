import { combineReducers } from "redux";
import events from "./events";
import login from "./login";
import loginFail from "./loginFail";
import loginStats from "./loginStats";
import houseInfo from "./houseInfo";
import places from "./places";

export default combineReducers({
  events,
  login,
  loginFail,
  loginStats,
  houseInfo,
  places,
});
