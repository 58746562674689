import { LOGIN_FAIL, TRY_AGAIN } from '../actions/login';

let newCount;
let updatedLoading;

// eslint-disable-next-line
export default (state = { count: 0, loading: false }, action = {}) => {
    switch (action.type) {
        case LOGIN_FAIL:
            newCount = state.count + 1;
            updatedLoading = { count: newCount, loading: true };
            return updatedLoading;
        case TRY_AGAIN:
            updatedLoading = { ...state, loading: false };
            return updatedLoading;
        default:
            return state;
    }
};
