import { PLACES_FETCHED, PLACE_CREATE_SUCCESS, PLACE_DELETE_SUCCESS, PLACE_EDIT_SUCCESS } from '../actions/places';

let newState = [];
let filteredPlaces = [];
let updatedState = [];

// eslint-disable-next-line
export default (state = null, action = {}) => {
    switch (action.type) {
        case PLACES_FETCHED:
            if (state) {
                return [...state, ...action.payload];
            } else {
                return action.payload;
            }
        case PLACE_CREATE_SUCCESS:
            newState = [...state, action.payload];
            return newState;
        case PLACE_DELETE_SUCCESS:
            filteredPlaces = state.filter((place) => {
                return place.id !== action.payload;
            });
            return [...filteredPlaces];
        case PLACE_EDIT_SUCCESS:
            updatedState = state.map((place) => {
                if (place.id === action.payload.id) {
                    place.name = action.payload.name;
                    place.location = action.payload.location;
                    place.locationLink = action.payload.locationLink;
                    place.description = action.payload.description;
                    place.category = action.payload.category;
                    place.picture = action.payload.picture;
                }
                return place;
            });
            return [...updatedState];
        default:
            return state;
    }
};
