import React from "react";
import {
  Col,
  Row,
  Card,
  CardText,
  Button,
  UncontrolledCollapse,
} from "reactstrap";

const UserHouseInfo = (props) => {
  return (
    <div className="userHouseInfo--container">
      {props.houseInfo ? (
        <Row className="userHouseInfo--row">
          <Col xs={12} sm={10} md={10} lg={7}>
            {props.houseInfo.length > 0 ? (
              <Row>
                {props.houseInfo.map((infoBlock) => {
                  return (
                    <Col
                      xs={12}
                      key={infoBlock.id}
                      className="userHouseInfo--col"
                    >
                      <UncontrolledCollapse
                        toggler={"toggler" + infoBlock.id}
                        className="userHouseInfo--collapse"
                      >
                        <Card body className="userHouseInfo--card">
                          <CardText>{infoBlock.answer}</CardText>
                        </Card>
                      </UncontrolledCollapse>
                      <Button
                        type="button"
                        className="userHouseInfo--questionButton"
                        block
                        size="lg"
                        id={"toggler" + infoBlock.id}
                      >
                        <span className="userHouseInfo--questionSpan">
                          {infoBlock.question}
                          <i className="far fa-caret-square-right userHouseInfo--questionIcon"></i>
                        </span>
                      </Button>
                    </Col>
                  );
                })}
              </Row>
            ) : (
              <p className="text-center h4">Nincs még megjeleníthető infó.</p>
            )}
          </Col>
        </Row>
      ) : (
        <div className="spinner"></div>
      )}
    </div>
  );
};

export default UserHouseInfo;
