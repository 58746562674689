import React, { useState, useEffect } from "react";
import { Container, Button, Row, ListGroup, ListGroupItem } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { login, confirmTryAgain } from "../actions/login";
// import { Redirect } from "react-router";
import FailedLoginModal from "./FailedLoginModal";
// import request from "superagent";

const LoginPage = (props) => {
  const dispatch = useDispatch();

  const loginState = useSelector((state) => state.login);
  const loginFail = useSelector((state) => state.loginFail);

  const [pinCode, setPinCode] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [showStar, setShowStar] = useState({
    one: false,
    two: false,
    three: false,
    four: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    switch (pinCode.length) {
      case 1:
        setShowStar({ ...showStar, one: true });
        break;
      case 2:
        setShowStar({ ...showStar, two: true });
        break;
      case 3:
        setShowStar({ ...showStar, three: true });
        break;
      case 4:
        setIsLoading(true);
        setShowStar({ ...showStar, four: true });
        dispatch(login(pinCode));
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [pinCode, dispatch]);

  useEffect(() => {
    if (loginFail.loading) {
      modalToggler();
    }
    // eslint-disable-next-line
  }, [loginFail.loading]);

  useEffect(() => {
    if (loginState) {
      props.history.push("/");
    }
    // eslint-disable-next-line
  }, [loginState]);

  const pinOnClickHandler = (event) => {
    event.preventDefault();
    if (pinCode.length < 4) {
      setPinCode([...pinCode, event.target.value]);
    }
  };

  const tryAgain = () => {
    dispatch(confirmTryAgain());
    setPinCode([]);
    setShowStar({
      one: false,
      two: false,
      three: false,
      four: false,
    });
    setIsLoading(false);
    modalToggler();
  };

  const modalToggler = () => {
    setModalOpen(!modalOpen);
  };
  // CREATING NEW USER FOR EXTERNAL DB ON HEROKU
  // const postUser = (event) => {
  //   event.preventDefault();

  //   request
  //     .post(`https://still-sea-92534.herokuapp.com/user`)
  //     .send({
  //       role: "user",
  //       password: 8174,
  //     })
  //     .then((response) => {
  //       console.log("user creation success: ", response.body);
  //     })
  //     .catch(console.error);
  // };

  return (
    <Container className="login_container">
      <FailedLoginModal tryAgain={tryAgain} modalOpen={modalOpen} />

      {/* {loginFailCount > 0 &&
        pinCode.length === 4 &&
        alert("Upsz, valamit elgépeltél!")} */}

      {/* {loginFail.count > 0 && loginFail.loading && (
        <Button type="button" onClick={tryAgain}>
          Try again
        </Button>
      )} */}

      {/* {loginState && <Redirect to="/"></Redirect>} */}
      {/* {loginFail.count === 3 && <Redirect to="/forbidden"></Redirect>} */}

      <Row className="d-flex justify-content-center align-items-center text-center login_text">
        <h2>Kérlek, add meg a jelszót!</h2>
      </Row>

      {/* <Button type="button" onClick={postUser}>
        Add user
      </Button> */}

      <div className="login_star--container">
        <ListGroup horizontal className="justify-content-center">
          <ListGroupItem tag="li" className="login_star--item">
            &nbsp;
          </ListGroupItem>
          <ListGroupItem
            tag="li"
            className="login_star--item"
            style={{ display: !showStar.one && "none" }}
          >
            &lowast;
          </ListGroupItem>
          <ListGroupItem
            tag="li"
            className="login_star--item"
            style={{ display: !showStar.two && "none" }}
          >
            &lowast;
          </ListGroupItem>
          <ListGroupItem
            tag="li"
            className="login_star--item"
            style={{ display: !showStar.three && "none" }}
          >
            &lowast;
          </ListGroupItem>
          <ListGroupItem
            tag="li"
            className="login_star--item"
            style={{ display: !showStar.four && "none" }}
          >
            &lowast;
          </ListGroupItem>
          <ListGroupItem tag="li" className="login_star--item">
            &nbsp;
          </ListGroupItem>
        </ListGroup>
      </div>

      {isLoading ? (
        <div className="spinner"></div>
      ) : (
        <div className="login_buttonContainer">
          <Row className="d-flex justify-content-center align-items-center">
            <Button
              color="primary"
              size="lg"
              name="one"
              value={1}
              onClick={pinOnClickHandler}
              className="login_pinButton"
            >
              1
            </Button>
            <Button
              color="primary"
              size="lg"
              name="two"
              value={2}
              onClick={pinOnClickHandler}
              className="login_pinButton"
            >
              2
            </Button>
            <Button
              color="primary"
              size="lg"
              name="three"
              value={3}
              onClick={pinOnClickHandler}
              className="login_pinButton"
            >
              3
            </Button>
          </Row>

          <Row className="d-flex justify-content-center align-items-center">
            <Button
              color="primary"
              size="lg"
              name="four"
              value={4}
              onClick={pinOnClickHandler}
              className="login_pinButton"
            >
              4
            </Button>
            <Button
              color="primary"
              size="lg"
              name="five"
              value={5}
              onClick={pinOnClickHandler}
              className="login_pinButton"
            >
              5
            </Button>
            <Button
              color="primary"
              size="lg"
              name="six"
              value={6}
              onClick={pinOnClickHandler}
              className="login_pinButton"
            >
              6
            </Button>
          </Row>

          <Row className="d-flex justify-content-center align-items-center">
            <Button
              color="primary"
              size="lg"
              name="seven"
              value={7}
              onClick={pinOnClickHandler}
              className="login_pinButton"
            >
              7
            </Button>
            <Button
              color="primary"
              size="lg"
              name="eight"
              value={8}
              onClick={pinOnClickHandler}
              className="login_pinButton"
            >
              8
            </Button>
            <Button
              color="primary"
              size="lg"
              name="nine"
              value={9}
              onClick={pinOnClickHandler}
              className="login_pinButton"
            >
              9
            </Button>
          </Row>

          <Row className="d-flex justify-content-center align-items-center">
            <Button
              color="primary"
              size="lg"
              name="zero"
              value={0}
              onClick={pinOnClickHandler}
              className="login_pinButton"
            >
              0
            </Button>
          </Row>
        </div>
      )}
    </Container>
  );
};

export default LoginPage;
