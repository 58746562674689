import request from "superagent";
import { baseURL } from "../constants";

// fetch login statistics
export const LOGIN_STATS_FETCHED = "LOGIN_STATS_FETCHED";

const statsFetched = (stats) => ({
  type: LOGIN_STATS_FETCHED,
  payload: stats,
});

export const fetchLoginStats = () => async (dispatch, getState) => {
  const store = getState();
  const { login } = store;

  request
    .get(`${baseURL}/loginStats`)
    .set("Authorization", `Bearer ${login.role}`)
    .then((response) => {
      dispatch(statsFetched(response.body));
    })
    .catch(console.error);
};

// clear stats
export const LOGIN_STATS_CLEARED = "LOGIN_STATS_CLEARED";

const statsCleared = () => ({
  type: LOGIN_STATS_CLEARED,
});

export const clearLoginStats = () => async (dispatch, getState) => {
  const store = getState();
  const { login } = store;

  request
    .delete(`${baseURL}/clearLoginStats`)
    .set("Authorization", `Bearer ${login.role}`)
    .then(() => {
      dispatch(statsCleared());
    })
    .catch(console.error);
};
