import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.css";
import bootstrapPlugin from "@fullcalendar/bootstrap";

const ReadOnlyCalendar = (props) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="calendarComponent">
      {isLoading && <div className="spinner"></div>}

      {props.events && (
        <FullCalendar
          plugins={[dayGridPlugin, bootstrapPlugin]}
          themeSystem="bootstrap"
          initialView="dayGridMonth"
          editable={false}
          selectable={false}
          firstDay={1}
          //initialEvents={!props.isWaiting && [...props.events]} // alternatively, use the `events` setting to fetch from a feed

          eventContent={props.renderEventContent} // custom render function for the event
          eventDisplay="background"
          events={props.events}
          loading={(bool) => setIsLoading(bool)}
          eventBackgroundColor="#dd6227"
          headerToolbar={{
            start: "",
            center: "title",
            end: "",
          }}
          footerToolbar={{
            start: "prev",
            center: "today",
            end: "next",
          }}
          height="100%"

          // titleFormat={{ year: "numeric", month: "2-digit", day: "2-digit" }}
          // eventContent={renderEventContent} // When you’re using the React connector, it’s possible to return React JSX nodes.
        />
      )}
    </div>
  );
};

export default ReadOnlyCalendar;
