import { EVENTS_FETCHED, EVENT_CREATE_SUCCESS, EVENT_DELETE_SUCCESS, EVENT_EDIT_SUCCESS } from '../actions/events';

let calendarInput = [];
let filteredEvents = [];
let updatedState = [];

// eslint-disable-next-line
export default (state = null, action = {}) => {
    switch (action.type) {
        case EVENTS_FETCHED:
            calendarInput = action.payload.map((event) => {
                return {
                    id: event.id,
                    title: event.name,
                    start: event.startDate,
                    end: event.endDate,
                    allDay: true,
                };
            });
            return calendarInput;
        case EVENT_CREATE_SUCCESS:
            state.push(action.payload);
            return [...state];
        case EVENT_DELETE_SUCCESS:
            filteredEvents = state.filter((event) => {
                return event.id !== action.payload;
            });
            return [...filteredEvents];
        case EVENT_EDIT_SUCCESS:
            updatedState = state.map((event) => {
                if (event.id === action.payload.id) {
                    event.start = action.payload.startDate;
                    event.end = action.payload.endDate;
                }
                return event;
            });
            return [...updatedState];
        default:
            return state;
    }
};
