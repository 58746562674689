import request from "superagent";
import { baseURL } from "../constants";

// login
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

const loggedIn = (role) => ({
  type: LOGIN_SUCCESS,
  payload: role,
});

export const LOGIN_FAIL = "LOGIN_FAIL";

const loginFail = () => ({
  type: LOGIN_FAIL,
});

export const login = (pinCode) => async (dispatch, getState) => {
  // const store = getState();
  //   const { auth } = store;
  const pin = Number(pinCode.join(""));

  request
    .get(`${baseURL}/login/${pin}`)
    .then((response) => {
      if (response.body.role === "dev") {
        dispatch(loggedIn({ role: "user" }));
      } else {
        dispatch(loggedIn(response.body));
      }
    })
    .catch((err) => {
      dispatch(loginFail(err));
      console.log(err);
    });
};

export const TRY_AGAIN = "TRY_AGAIN";

const tryAgain = () => ({
  type: TRY_AGAIN,
});

export const confirmTryAgain = () => async (dispatch) => {
  dispatch(tryAgain());
};
