import React from "react";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";

const FailedLoginModal = (props) => {
  const { modalOpen, tryAgain } = props;

  const closeBtn = <button className="failedModal_closeButton">&times;</button>;

  return (
    <div>
      <Modal
        isOpen={modalOpen}
        centered
        size="sm"
        className="failedModal_modal"
      >
        <ModalHeader
          close={closeBtn}
          className="border-bottom-0 mb-3 mt-3 failedModal failedModal_header"
        >
          Upsz, valamit elgépeltél!
        </ModalHeader>

        <ModalFooter className="border-top-0 mt-4 mb-4 d-flex justify-content-center failedModal failedModal_footer">
          <Button size="lg" onClick={tryAgain} className="failedModal_button">
            Megpróbálom újra
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default FailedLoginModal;
