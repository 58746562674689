import request from "superagent";
import { baseURL } from "../constants";

// fetching all house info
export const HOUSE_INFO_FETCHED = "HOUSE_INFO_FETCHED";

const houseInfoFetched = (houseInfo) => ({
  type: HOUSE_INFO_FETCHED,
  payload: houseInfo,
});

export const loadHouseInfo = () => async (dispatch, getState) => {
  const store = getState();
  const { login } = store;

  request
    .get(`${baseURL}/getHouseInfo`)
    .set("Authorization", `Bearer ${login.role}`)
    .then((response) => {
      dispatch(houseInfoFetched(response.body));
    })
    .catch(console.error);

  // const houseInfo = [
  //   {
  //     infoId: "hhb",
  //     question: "Mi a pontos cim?",
  //     answer: "Balatonkenese, Petofi utca",
  //   },
  //   {
  //     infoId: "wrg",
  //     question: "Hogy kell hasznalni a mosogepet?",
  //     answer: "Dugd be a konnektorba.",
  //   },
  //   {
  //     infoId: "1442",
  //     question:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry?",
  //     answer:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //   },
  //   {
  //     infoId: "2453",
  //     question:
  //       "It has survived not only five centuries, but also the leap into electronic?",
  //     answer:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //   },
  //   {
  //     infoId: "6234",
  //     question:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry?",
  //     answer:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //   },
  //   {
  //     infoId: "1234",
  //     question:
  //       "It has survived not only five centuries, but also the leap into electronic?",
  //     answer:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //   },
  // ];

  // dispatch(houseInfoFetched(houseInfo));
};

// creating a new house info
export const HOUSE_INFO_CREATE_SUCCESS = "HOUSE_INFO_CREATE_SUCCESS";

const houseInfoCreateSuccess = (houseInfo) => ({
  type: HOUSE_INFO_CREATE_SUCCESS,
  payload: houseInfo,
});

export const createHouseInfo = (data) => (dispatch, getState) => {
  const state = getState();
  const { login } = state;

  request
    .post(`${baseURL}/postHouseInfo`)
    .set("Authorization", `Bearer ${login.role}`)
    .send(data)
    .then((response) => {
      dispatch(houseInfoCreateSuccess(response.body));
    })
    .catch(console.error);
};

// deleting a house info
export const HOUSE_INFO_DELETE_SUCCESS = "HOUSE_INFO_DELETE_SUCCESS";

const houseInfoDeleteSuccess = (houseInfoId) => ({
  type: HOUSE_INFO_DELETE_SUCCESS,
  payload: houseInfoId,
});

export const deleteHouseInfo = (id) => (dispatch, getState) => {
  const state = getState();
  const { login } = state;

  request
    .delete(`${baseURL}/deleteHouseInfo/${id}`)
    .set("Authorization", `Bearer ${login.role}`)
    .then(() => {
      dispatch(houseInfoDeleteSuccess(id));
    })
    .catch(console.error);
};

// editing a house info
export const HOUSE_INFO_EDIT_SUCCESS = "HOUSE_INFO_EDIT_SUCCESS";

const houseInfoEditSuccess = (newData) => ({
  type: HOUSE_INFO_EDIT_SUCCESS,
  payload: newData,
});

export const editHouseInfo = (values) => (dispatch, getState) => {
  const state = getState();
  const { login } = state;

  request
    .put(`${baseURL}/editHouseInfo/${values.id}`)
    .set("Authorization", `Bearer ${login.role}`)
    .send(values)
    .then((response) => {
      dispatch(houseInfoEditSuccess(response.body));
    })
    .catch(console.error);
};
