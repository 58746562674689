import {
    HOUSE_INFO_FETCHED,
    HOUSE_INFO_CREATE_SUCCESS,
    HOUSE_INFO_DELETE_SUCCESS,
    HOUSE_INFO_EDIT_SUCCESS,
} from '../actions/houseInfo';

let newState = [];
let filteredHouseInfo = [];
let updatedState = [];

// eslint-disable-next-line
export default (state = null, action = {}) => {
    switch (action.type) {
        case HOUSE_INFO_FETCHED:
            return action.payload;
        case HOUSE_INFO_CREATE_SUCCESS:
            newState = [...state, action.payload];
            return newState;
        case HOUSE_INFO_DELETE_SUCCESS:
            filteredHouseInfo = state.filter((houseInfo) => {
                return houseInfo.id !== action.payload;
            });
            return [...filteredHouseInfo];
        case HOUSE_INFO_EDIT_SUCCESS:
            updatedState = state.map((houseInfo) => {
                if (houseInfo.id === action.payload.id) {
                    houseInfo.question = action.payload.question;
                    houseInfo.answer = action.payload.answer;
                }
                return houseInfo;
            });
            return [...updatedState];
        default:
            return state;
    }
};
