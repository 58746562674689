import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Calendar from "./Calendar";
// import { createEventId } from "../Utils/event-utils";
import {
  loadEvents,
  createEvent,
  deleteEvent,
  editEvent,
} from "../actions/events";
// import { Redirect } from "react-router";
import ReadOnlyCalendar from "./ReadOnlyCalendar";
import { Badge } from "reactstrap";

const CalendarContainer = (props) => {
  const dispatch = useDispatch();

  const login = useSelector((state) => state.login);
  const events = useSelector((state) => state.events);

  const [selectedEventInfo, setSelectedEventInfo] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [menuMode, setMenuMode] = useState(false);

  useEffect(() => {
    login && dispatch(loadEvents());
  }, [login, dispatch]);

  const handleDateSelect = (selectInfo) => {
    let endDateCalc = new Date();
    endDateCalc.setDate(new Date(selectInfo.end).getDate() - 1);

    let title = prompt(
      `Add meg a vendég nevét, aki ${
        selectInfo.startStr
      }-kor jönne ${endDateCalc.toISOString().substring(0, 10)}-ig.`
    );
    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect(); // clear date selection

    // console.log("this is info and prompt: ", selectInfo);

    if (title) {
      dispatch(
        createEvent({
          name: title,
          startDate: selectInfo.startStr,
          endDate: selectInfo.endStr,
        })
      );

      calendarApi.addEvent(
        {
          // built-in function for adding the event to the front-end calendar
          // id: createEventId(),
          title,
          start: selectInfo.startStr,
          end: selectInfo.endStr,
          allDay: selectInfo.allDay,
        },
        true
      );
    }
  };

  // const handleEventAdd = (addedEventInfo) => {
  //   dispatch(loadEvents());
  // };

  // const handleEventsSet = (events) => { // gets called after every events change
  //   console.log("this is set: ", events);
  // };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <h3 className="eventTitle">
          {login && login.role === "admin" && eventInfo.event.title}
        </h3>
      </>
    );
  };

  const handleEventClick = (clickedEventInfo) => {
    //console.log("this is onclick: ", clickedEventInfo);

    clickedEventInfo.event.setProp("backgroundColor", "red");
    setSelectedEventInfo(clickedEventInfo);

    // setEditMode(true);
    setMenuMode(true);
  };

  const eventOnDelete = () => {
    // selectedEventInfo.event.setProp("backgroundColor", "");
    selectedEventInfo.event.remove();

    dispatch(deleteEvent(parseInt(selectedEventInfo.event.id)));
    setMenuMode(false);
  };

  const eventOnEditClik = () => {
    setEditMode(true);
    selectedEventInfo.event.remove();
    alert("Válaszd ki az új idősávot az üzenet bezárása után!");
  };

  const handleEditing = (selectInfo) => {
    let calendarApi = selectInfo.view.calendar;
    calendarApi.addEvent(
      {
        // built-in function for adding the event to the front-end calendar
        id: selectedEventInfo.event.id,
        title: selectedEventInfo.event.title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      },
      true
    );
    calendarApi.unselect();

    dispatch(
      editEvent(parseInt(selectedEventInfo.event.id), {
        startDate: selectInfo.startStr,
        endDate: selectInfo.endStr,
      })
    );
    setEditMode(false);
    setMenuMode(false);
  };

  const handleOnCancel = () => {
    selectedEventInfo.event.setProp("backgroundColor", "");
    //setEditMode(false);
    setMenuMode(false);
  };

  return (
    <div className="calendarContainer">
      {/* {!login && <Redirect to="/login"></Redirect>} */}

      <p className="calendar_text">Mikor mehetek?</p>

      {login && login.role === "user" && (
        <span className="calendarContainer_reservedSpan">
          <Badge className="calendarContainer_reservedBadge">&nbsp;</Badge>
          foglalt
        </span>
      )}

      {login && login.role === "admin" && (
        <Calendar
          handleDateSelect={handleDateSelect}
          renderEventContent={renderEventContent}
          events={events}
          handleEventClick={handleEventClick}
          eventOnDelete={eventOnDelete}
          editMode={editMode}
          menuMode={menuMode}
          eventOnEditClik={eventOnEditClik}
          handleEditing={handleEditing}
          handleOnCancel={handleOnCancel}
        />
      )}
      {login && login.role === "user" && (
        <ReadOnlyCalendar
          renderEventContent={renderEventContent}
          events={events}
        />
      )}
    </div>
  );
};

export default CalendarContainer;
