import request from "superagent";
import { baseURL } from "../constants";

// fetching all events
export const EVENTS_FETCHED = "EVENTS_FETCHED";

const eventsFetched = (events) => ({
  type: EVENTS_FETCHED,
  payload: events,
});

export const loadEvents = () => async (dispatch, getState) => {
  const store = getState();
  const { login } = store;

  // when the state already contains events, we don't fetch them again
  // if (store.events) return;

  request
    .get(`${baseURL}/getEvents`)
    .set("Authorization", `Bearer ${login.role}`)
    .then((response) => {
      dispatch(eventsFetched(response.body));
    })
    .catch(console.error);
};

// creating a new event
export const EVENT_CREATE_SUCCESS = "EVENT_CREATE_SUCCESS";

const eventCreateSuccess = (event) => ({
  type: EVENT_CREATE_SUCCESS,
  payload: event,
});

export const createEvent = (data) => (dispatch, getState) => {
  const state = getState();
  const { login } = state;

  request
    .post(`${baseURL}/postEvent`)
    .set("Authorization", `Bearer ${login.role}`)
    .send(data)
    .then((response) => {
      dispatch(eventCreateSuccess(response.body));
      dispatch(loadEvents());
    })
    .catch(console.error);
};

// deleting an event
export const EVENT_DELETE_SUCCESS = "EVENT_DELETE_SUCCESS";

const eventDeleteSuccess = (eventId) => ({
  type: EVENT_DELETE_SUCCESS,
  payload: eventId,
});

export const deleteEvent = (id) => (dispatch, getState) => {
  const state = getState();
  const { login } = state;

  request
    .delete(`${baseURL}/deleteEvent/${id}`)
    .set("Authorization", `Bearer ${login.role}`)
    .then(() => {
      dispatch(eventDeleteSuccess(id));
    })
    .catch(console.error);
};

// editing an event
export const EVENT_EDIT_SUCCESS = "EVENT_EDIT_SUCCESS";

const eventEditSuccess = (newData) => ({
  type: EVENT_EDIT_SUCCESS,
  payload: newData,
});

export const editEvent = (id, values) => (dispatch, getState) => {
  const state = getState();
  const { login } = state;

  request
    .put(`${baseURL}/editEvent/${id}`)
    .set("Authorization", `Bearer ${login.role}`)
    .send(values)
    .then((response) => {
      dispatch(eventEditSuccess(response.body));
    })
    .catch(console.error);
};
