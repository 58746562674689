import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
// import Icon from "@material-ui/core/Icon";
import SvgIcon from "@material-ui/core/SvgIcon";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
// import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
  root: {
    // height: 380,
    zIndex: 10,
    transform: "translateZ(0px)",
    flexGrow: 1,
    position: "fixed",
    bottom: 0,
    right: 0,
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fab: {
    backgroundColor: "#ffcc33",
    color: "black",
    "&:hover,&:active": {
      backgroundColor: "#ffcc33",
      color: "black",
      outline: "none",
    },
  },
  speedDialIcon: {
    fontSize: "2.5rem",
  },
  actionLabel: {
    // minWidth: "60vw",
    minWidth: "max-content",
    textAlign: "right",
    backgroundColor: "black",
    color: "#ffcc33",
    fontSize: "1.2rem",
    border: "1px solid #ffcc33",
  },
}));

const actions = [
  {
    icon: (
      <SvgIcon width="1em" height="1em" viewBox="0 0 16 16">
        <path
          fillRule="evenodd"
          d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zM9.5 7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM2 10.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"
        />
      </SvgIcon>
    ),
    name: "Mikor mehetek?",
    tabNumber: 1,
  },
  // {
  //   icon: <i className="far fa-caret-square-right" />,
  //   name: "Save",
  //   tabNumber: 2,
  // },
  {
    icon: (
      <SvgIcon width="1em" height="1em" viewBox="0 0 16 16">
        <path
          fillRule="evenodd"
          d="M0 1l1-1 3.081 2.2a1 1 0 0 1 .419.815v.07a1 1 0 0 0 .293.708L10.5 9.5l.914-.305a1 1 0 0 1 1.023.242l3.356 3.356a1 1 0 0 1 0 1.414l-1.586 1.586a1 1 0 0 1-1.414 0l-3.356-3.356a1 1 0 0 1-.242-1.023L9.5 10.5 3.793 4.793a1 1 0 0 0-.707-.293h-.071a1 1 0 0 1-.814-.419L0 1zm11.354 9.646a.5.5 0 0 0-.708.708l3 3a.5.5 0 0 0 .708-.708l-3-3z"
        />
        <path
          fillRule="evenodd"
          d="M15.898 2.223a3.003 3.003 0 0 1-3.679 3.674L5.878 12.15a3 3 0 1 1-2.027-2.027l6.252-6.341A3 3 0 0 1 13.778.1l-2.142 2.142L12 4l1.757.364 2.141-2.141zm-13.37 9.019L3.001 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026z"
        />
      </SvgIcon>
    ),
    name: "Mi hogy működik a házban?",
    tabNumber: 3,
  },
  {
    icon: (
      <SvgIcon width="1em" height="1em" viewBox="0 0 16 16">
        <path
          fillRule="evenodd"
          d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
        />
      </SvgIcon>
    ),
    name: "Mit lehet csinálni a környéken?",
    tabNumber: 4,
  },
  // {
  //   icon: <i className="far fa-caret-square-right" />,
  //   name: "Like",
  //   tabNumber: 5,
  // },
];

export default function MenuFab({
  open,
  handleClose,
  handleOpen,
  menuIconClick,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <Backdrop open={open} /> */}
      <SpeedDial
        ariaLabel="SpeedDial with tooltips"
        icon={
          <SpeedDialIcon
            icon={
              <MenuIcon
                fontSize="large"
                classes={{ fontSizeLarge: classes.speedDialIcon }}
              />
            }
            openIcon={
              <CloseIcon
                fontSize="large"
                classes={{ fontSizeLarge: classes.speedDialIcon }}
              />
            }
          />
        }
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        classes={{
          root: classes.speedDial,
          fab: classes.fab,
        }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={(event) => menuIconClick(event, action.tabNumber)}
            classes={{
              fab: classes.fab,
              staticTooltipLabel: classes.actionLabel,
            }}
          />
        ))}
      </SpeedDial>
    </div>
  );
}
