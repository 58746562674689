import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminPlaces from "./AdminPlaces";
import UserPlaces from "./UserPlaces";
import {
  loadPlaces,
  createPlace,
  deletePlace,
  editPlace,
} from "../../actions/places";

const PlacesContainer = () => {
  const login = useSelector((state) => state.login);
  const places = useSelector((state) => state.places);

  const [newPlace, setNewPlace] = useState({
    name: "",
    location: "",
    locationLink: "",
    description: "",
    category: "Gyerekes helyek",
    picture: "",
  });
  const [placeToEdit, setPlaceToEdit] = useState(null);

  const [activeTab, setActiveTab] = useState("Gyerekes helyek");

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (!places) {
      dispatch(loadPlaces());
    }
  }, [places, dispatch]);

  const sortingToCardDecks = (deckCount, filteredPlaces) => {
    let cardDecks = []; // [{deckIndex: 0, placeArray: []}, {deckIndex: 1, placeArray: []}]

    for (let deckIndex = 0; deckIndex < deckCount; deckIndex++) {
      cardDecks.push({
        deckIndex: deckIndex,
        placeArray: [],
      });
    }

    if (filteredPlaces && filteredPlaces.length > 0) {
      for (let i = 0; i < filteredPlaces.length; i++) {
        const placeObject = filteredPlaces[i];
        const currentCardDeckIndex = Math.floor(i / 2); // e.g. place with index 5 will belong to card index 2
        cardDecks[currentCardDeckIndex].placeArray.push(placeObject);
      }
    }
    return cardDecks;
  };

  const cardDeckCountCalculator = (placesLength) => {
    const cardDeckCountRemainder = placesLength % 2;
    const cardDeckCount =
      cardDeckCountRemainder === 0
        ? placesLength / 2
        : cardDeckCountRemainder === 1
        ? Math.floor(placesLength / 2) + 1
        : null;

    return cardDeckCount;
  };

  const gyerekesCat =
    places &&
    places.length > 0 &&
    places.filter((place) => place.category === "Gyerekes helyek");
  const strandCat =
    places &&
    places.length > 0 &&
    places.filter((place) => place.category === "Strandok");
  const etteremCat =
    places &&
    places.length > 0 &&
    places.filter((place) => place.category === "Éttermek");
  const kirandulasCat =
    places &&
    places.length > 0 &&
    places.filter((place) => place.category === "Kirándulás");

  const gyerekesCardDecks =
    gyerekesCat && gyerekesCat.length > 0
      ? sortingToCardDecks(
          cardDeckCountCalculator(gyerekesCat.length),
          gyerekesCat
        )
      : [];
  const strandCardDecks =
    strandCat && strandCat.length > 0
      ? sortingToCardDecks(cardDeckCountCalculator(strandCat.length), strandCat)
      : [];
  const etteremCardDecks =
    etteremCat && etteremCat.length > 0
      ? sortingToCardDecks(
          cardDeckCountCalculator(etteremCat.length),
          etteremCat
        )
      : [];
  const kirandulasCardDecks =
    kirandulasCat && kirandulasCat.length > 0
      ? sortingToCardDecks(
          cardDeckCountCalculator(kirandulasCat.length),
          kirandulasCat
        )
      : [];

  const newPlaceOnChange = (event) => {
    if (event.target.name === "picture") {
      let file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = _handleReaderLoaded;
        reader.readAsBinaryString(file);
      }
    } else {
      if (event.target.name === "category" && event.target.value === "") {
        setNewPlace({
          ...newPlace,
          [event.target.name]: "Gyerekes helyek",
        });
      } else {
        setNewPlace({
          ...newPlace,
          [event.target.name]: event.target.value,
        });
      }
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setNewPlace({
      ...newPlace,
      picture: btoa(binaryString),
    });
  };

  const saveNewPlace = (event) => {
    event.preventDefault();
    dispatch(createPlace(newPlace));
    setNewPlace({
      name: "",
      location: "",
      locationLink: "",
      description: "",
      category: "Gyerekes helyek",
      picture: "",
    });
  };

  const deletePlaceOnClick = (event, id) => {
    event.preventDefault();
    dispatch(deletePlace(id));
  };

  const editPlaceOnClick = (event, placeToEdit) => {
    event.preventDefault();
    setPlaceToEdit(placeToEdit);
  };

  const editPlaceOnChange = (event) => {
    if (event.target.name === "picture") {
      let file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = _handleReaderLoadedForEditedPlace;
        reader.readAsBinaryString(file);
      }
    } else {
      if (event.target.name === "category" && event.target.value === "") {
        setPlaceToEdit({
          ...placeToEdit,
          [event.target.name]: "Gyerekes helyek",
        });
      } else {
        setPlaceToEdit({
          ...placeToEdit,
          [event.target.name]: event.target.value,
        });
      }
    }
  };

  const _handleReaderLoadedForEditedPlace = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setPlaceToEdit({
      ...placeToEdit,
      picture: btoa(binaryString),
    });
  };

  const saveEditedPlace = (event) => {
    event.preventDefault();
    dispatch(editPlace(placeToEdit));
    setPlaceToEdit(null);
  };

  const cancelEditingPlace = (event) => {
    event.preventDefault();
    setPlaceToEdit(null);
  };

  return (
    <div className="placesContainer">
      <p className="calendar_text mb-5">Mit lehet csinálni a környéken?</p>

      {login && login.role === "user" && (
        <UserPlaces
          places={places}
          activeTab={activeTab}
          toggleTab={toggleTab}
          gyerekesCardDecks={gyerekesCardDecks}
          strandCardDecks={strandCardDecks}
          etteremCardDecks={etteremCardDecks}
          kirandulasCardDecks={kirandulasCardDecks}
        />
      )}

      {login && login.role === "admin" && (
        <AdminPlaces
          places={places}
          newPlace={newPlace}
          onSubmit={saveNewPlace}
          onChange={newPlaceOnChange}
          deletePlaceOnClick={deletePlaceOnClick}
          editPlaceOnClick={editPlaceOnClick}
          placeToEdit={placeToEdit}
          editPlaceOnChange={editPlaceOnChange}
          saveEditedPlace={saveEditedPlace}
          cancelEditingPlace={cancelEditingPlace}
          activeTab={activeTab}
          toggleTab={toggleTab}
          gyerekesCardDecks={gyerekesCardDecks}
          strandCardDecks={strandCardDecks}
          etteremCardDecks={etteremCardDecks}
          kirandulasCardDecks={kirandulasCardDecks}
        />
      )}

      <div className="bottomGap"></div>
    </div>
  );
};

export default PlacesContainer;
