import React from "react";

const ForbiddenPage = () => {
  return (
    <div className="mt-5">
      <p className="forbidden_text">
        Úgy tűnik, nem jól tudod a kódot. Kérlek, járj utána és próbáld meg
        később!
      </p>
    </div>
  );
};

export default ForbiddenPage;
