import request from "superagent";
import { baseURL } from "../constants";

// fetching all places
export const PLACES_FETCHED = "PLACES_FETCHED";

const placesFetched = (places) => ({
  type: PLACES_FETCHED,
  payload: places,
});

export const loadPlaces = () => async (dispatch, getState) => {
  const store = getState();
  const { login } = store;

  // request
  //   .get(`${baseURL}/getPlaces`)
  //   .set("Authorization", `Bearer ${login.role}`)
  //   .then((response) => {
  //     dispatch(placesFetched(response.body));
  //   })
  //   .catch(console.error);

  request
    .get(`${baseURL}/getPlacesByCategory/Gyerekes helyek`)
    .set("Authorization", `Bearer ${login.role}`)
    .then((response) => {
      dispatch(placesFetched(response.body));

      request
        .get(`${baseURL}/getPlacesByCategory/Strandok`)
        .set("Authorization", `Bearer ${login.role}`)
        .then((response) => {
          dispatch(placesFetched(response.body));

          request
            .get(`${baseURL}/getPlacesByCategory/Éttermek`)
            .set("Authorization", `Bearer ${login.role}`)
            .then((response) => {
              dispatch(placesFetched(response.body));

              request
                .get(`${baseURL}/getPlacesByCategory/Kirándulás`)
                .set("Authorization", `Bearer ${login.role}`)
                .then((response) => {
                  dispatch(placesFetched(response.body));
                })
                .catch(console.error);
            })
            .catch(console.error);
        })
        .catch(console.error);
    })
    .catch(console.error);
};

// creating a new place
export const PLACE_CREATE_SUCCESS = "PLACE_CREATE_SUCCESS";

const placeCreateSuccess = (place) => ({
  type: PLACE_CREATE_SUCCESS,
  payload: place,
});

export const createPlace = (data) => (dispatch, getState) => {
  const state = getState();
  const { login } = state;

  request
    .post(`${baseURL}/postPlace`)
    .set("Authorization", `Bearer ${login.role}`)
    .send(data)
    .then((response) => {
      dispatch(placeCreateSuccess(response.body));
    })
    .catch(console.error);
};

// deleting a place
export const PLACE_DELETE_SUCCESS = "PLACE_DELETE_SUCCESS";

const placeDeleteSuccess = (placeId) => ({
  type: PLACE_DELETE_SUCCESS,
  payload: placeId,
});

export const deletePlace = (id) => (dispatch, getState) => {
  const state = getState();
  const { login } = state;

  request
    .delete(`${baseURL}/deletePlace/${id}`)
    .set("Authorization", `Bearer ${login.role}`)
    .then(() => {
      dispatch(placeDeleteSuccess(id));
    })
    .catch(console.error);
};

// editing a place
export const PLACE_EDIT_SUCCESS = "PLACE_EDIT_SUCCESS";

const placeEditSuccess = (newData) => ({
  type: PLACE_EDIT_SUCCESS,
  payload: newData,
});

export const editPlace = (values) => (dispatch, getState) => {
  const state = getState();
  const { login } = state;

  request
    .put(`${baseURL}/editPlace/${values.id}`)
    .set("Authorization", `Bearer ${login.role}`)
    .send(values)
    .then((response) => {
      dispatch(placeEditSuccess(response.body));
    })
    .catch(console.error);
};
