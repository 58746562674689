import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
// import { INITIAL_EVENTS } from "../Utils/event-utils";
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.css"; // needs additional webpack config!
import bootstrapPlugin from "@fullcalendar/bootstrap";
import { Button } from "reactstrap";
import { fetchLoginStats, clearLoginStats } from "../actions/loginStats";
import LoginStatsModal from "./LoginStatsModal";

const Calendar = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();

  const loginStatistics = useSelector((state) => state.loginStats);

  const loginStatsOnClickHandler = (event) => {
    event.preventDefault();
    dispatch(fetchLoginStats());
    toggleStatsModal();
  };

  const toggleStatsModal = () => {
    setModalOpen(!modalOpen);
  };

  const clearStats = (event) => {
    event.preventDefault();
    dispatch(clearLoginStats());
    toggleStatsModal();
  };

  return (
    <div className="calendarComponent">
      <div className="calendarComponent_buttonGroup">
        {props.menuMode && (
          <div>
            <Button
              className="calendarComponent_stat--button"
              type="button"
              onClick={props.eventOnDelete}
            >
              Delete event
            </Button>
            <Button
              className="calendarComponent_stat--button"
              type="button"
              onClick={props.eventOnEditClik}
            >
              Edit event
            </Button>
            <Button
              className="calendarComponent_stat--button"
              type="button"
              onClick={props.handleOnCancel}
            >
              Cancel
            </Button>
          </div>
        )}

        <div className="calendarComponent_stat--container">
          <Button
            type="button"
            className="calendarComponent_stat--button"
            onClick={loginStatsOnClickHandler}
          >
            Bejelentkezési statisztikák
          </Button>
        </div>
      </div>

      {isLoading && <div className="spinner"></div>}

      <LoginStatsModal
        toggleStatsModal={toggleStatsModal}
        modalOpen={modalOpen}
        loginStatistics={loginStatistics ? loginStatistics : null}
        clearStats={clearStats}
      />

      {props.events && (
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin, bootstrapPlugin]}
          themeSystem="bootstrap"
          initialView="dayGridMonth"
          editable={true}
          selectable={true}
          firstDay={1}
          //initialEvents={!props.isWaiting && [...props.events]} // alternatively, use the `events` setting to fetch from a feed
          select={props.editMode ? props.handleEditing : props.handleDateSelect}
          eventContent={props.renderEventContent} // custom render function for the event
          selectLongPressDelay={600}
          eventDisplay="background"
          eventOverlap={false} // event cannot be edited/dragged as overlapping
          selectOverlap={false} // event cannot be selected as overlapping
          eventClick={props.handleEventClick}
          events={props.events}
          //eventAdd={props.handleEventAdd}
          // eventsSet={props.handleEventsSet}
          loading={(bool) => setIsLoading(bool)}
          headerToolbar={{
            start: "",
            center: "title",
            end: "",
          }}
          footerToolbar={{
            start: "prev",
            center: "today",
            end: "next",
          }}
          height="100%"
          eventBackgroundColor="#dd6227"
          eventTextColor="#ffffff"

          // eventReceive={(addInfo) =>
          //   console.log(addInfo.view.calendar.getEventSources())
          // }
          // eventAdd={(newEvent) => {
          //   newEvent.event.setProp(
          //     "id",
          //     props.events[props.events.length - 1].id.toString()
          //   );
          //   console.log(newEvent);
          // }}

          // titleFormat={{ year: "numeric", month: "2-digit", day: "2-digit" }}
          // eventContent={renderEventContent} // When you’re using the React connector, it’s possible to return React JSX nodes.
        />
      )}
    </div>
  );
};

export default Calendar;
