import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { login } from "../actions/login";
import CalendarContainer from "./CalendarContainer";
import HouseInfoContainer from "./HouseInfo/HouseInfoContainer";
import PlacesContainer from "./RecommendedPlaces/PlacesContainer";
// import MenuFooter from "./MenuFooter";
import Backdrop from "@material-ui/core/Backdrop";
import MenuFab from "./MenuFab";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 5,
    backgroundColor: "rgba(216, 208, 208, 0.7)",
  },
}));

const MainContentManager = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [open, setOpen] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.login);

  useEffect(() => {
    if (location.pathname === "/" && !loginState) {
      dispatch(login([8, 1, 7, 4]));
    }
  }, [location, loginState, dispatch]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const menuIconClick = (event, tabNum) => {
    event.preventDefault();
    handleClose();
    setActiveTab(tabNum);
  };

  const classes = useStyles();

  return (
    <div className="mainContent">
      <Backdrop open={open} className={classes.backdrop} />
      {activeTab === 1 ? (
        <CalendarContainer />
      ) : activeTab === 3 ? (
        <HouseInfoContainer />
      ) : activeTab === 4 ? (
        <PlacesContainer />
      ) : (
        ""
      )}

      <MenuFab
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        menuIconClick={menuIconClick}
      />
      {/* <MenuFooter menuIconClick={menuIconClick} activeTab={activeTab} /> */}
    </div>
  );
};

export default MainContentManager;
