import React from "react";
import icon from "../assets/images/icon1.png";

const Header = () => {
  return (
    <div className="header">
      <img src={icon} alt="Sunflower icon" className="header_icon" />

      <div className="header_title">
        <h1 className="m-0">Napraforgó Kulcsosház</h1>
      </div>
    </div>
  );
};

export default Header;
