import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import Header from "./components/Header";
import LoginPage from "./components/LoginPage";
import ForbiddenPage from "./components/ForbiddenPage";
import MainContentManager from "./components/MainContentManager";

const App = () => {
  const loginState = useSelector((state) => state.login);
  const loginFail = useSelector((state) => state.loginFail);

  return (
    <div className="App">
      <Header />
      <Route exact path="/" component={MainContentManager} />
      <Route
        exact
        path="/admin"
        component={
          loginState
            ? MainContentManager
            : loginFail.count === 3
            ? ForbiddenPage
            : LoginPage
        }
      />
      {/* 
      <Route exact path="/" component={LoginPage} /> */}
      {/*path will be /calendar */}
      {/* <Route path="/forbidden" component={ForbiddenPage} /> */}
    </div>
  );
};

export default App;
