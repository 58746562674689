import React from "react";
import {
  Col,
  Row,
  Card,
  CardText,
  Button,
  UncontrolledCollapse,
  CardHeader,
  ButtonGroup,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const AdminHouseInfo = (props) => {
  return (
    <div className="userHouseInfo--container">
      {props.houseInfo ? (
        <Row className="userHouseInfo--row">
          <Col xs={12} sm={10} md={10} lg={7} className="userHouseInfo--col">
            <Form
              className="mb-5 adminHouseInfo--form"
              onSubmit={props.onSubmit}
            >
              <legend>Új infó hozzáadása</legend>
              <FormGroup>
                <Label for="question">Cím/Kérdés</Label>
                <Input
                  type="textarea"
                  name="question"
                  id="question"
                  placeholder="Pl. 'Hogy működik a mosógép?'"
                  value={props.newHouseInfo.question}
                  onChange={props.onChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="answer">Infó/Válasz</Label>
                <Input
                  type="textarea"
                  name="answer"
                  id="answer"
                  placeholder="Pl. 'Be kell dugni a konnektorba.'"
                  value={props.newHouseInfo.answer}
                  onChange={props.onChange}
                />
              </FormGroup>
              <Button
                type="submit"
                color="warning"
                className="adminHouseInfo--formButton"
              >
                Mentés
              </Button>
            </Form>
          </Col>

          <Col xs={12} sm={10} md={10} lg={7}>
            {props.houseInfo.length > 0 ? (
              <Row>
                {props.houseInfo.map((infoBlock) => {
                  // if the info block is being edited, show a form instead of the display
                  if (
                    props.houseInfoToEdit &&
                    props.houseInfoToEdit.id === infoBlock.id
                  ) {
                    return (
                      <Col xs={12} key={props.houseInfoToEdit.id}>
                        <Form
                          className="adminHouseInfo--form"
                          onSubmit={props.saveEditedHouseInfo}
                        >
                          <legend>Infó szerkesztése</legend>
                          <FormGroup>
                            <Label for="updatedQuestion">Cím/Kérdés</Label>
                            <Input
                              type="textarea"
                              name="question"
                              id="updatedQuestion"
                              placeholder="Pl. 'Hogy működik a mosógép?'"
                              value={props.houseInfoToEdit.question}
                              onChange={props.editHouseInfoOnChange}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="updatedAnswer">Infó/Válasz</Label>
                            <Input
                              type="textarea"
                              name="answer"
                              id="updatedAnswer"
                              placeholder="Pl. 'Be kell dugni a konnektorba.'"
                              value={props.houseInfoToEdit.answer}
                              onChange={props.editHouseInfoOnChange}
                            />
                          </FormGroup>
                          <Button
                            type="submit"
                            color="warning"
                            className="adminHouseInfo--formButton"
                          >
                            Mentés
                          </Button>
                          <Button
                            type="button"
                            color="warning"
                            className="adminHouseInfo--formButton"
                            onClick={props.cancelEditingHouseInfo}
                          >
                            Mégse
                          </Button>
                        </Form>
                      </Col>
                    );
                  } else {
                    return (
                      <Col
                        xs={12}
                        key={infoBlock.id}
                        className="userHouseInfo--col"
                      >
                        <UncontrolledCollapse
                          toggler={"toggler" + infoBlock.id}
                          className="userHouseInfo--collapse"
                        >
                          <Card body className="userHouseInfo--card">
                            <CardHeader className="adminHouseInfo--cardHeader p-1">
                              <ButtonGroup>
                                <Button
                                  type="button"
                                  size="lg"
                                  color="warning"
                                  outline
                                  onClick={(event) =>
                                    props.editHouseInfoOnClick(event, infoBlock)
                                  }
                                >
                                  <i className="far fa-edit"></i>
                                </Button>
                                <Button
                                  type="button"
                                  size="lg"
                                  color="warning"
                                  outline
                                  onClick={(event) =>
                                    props.deleteHouseInfoOnClick(
                                      event,
                                      infoBlock.id
                                    )
                                  }
                                >
                                  <i className="far fa-trash-alt"></i>
                                </Button>
                              </ButtonGroup>
                            </CardHeader>
                            <CardText>{infoBlock.answer}</CardText>
                          </Card>
                        </UncontrolledCollapse>
                        <Button
                          type="button"
                          className="userHouseInfo--questionButton"
                          block
                          size="lg"
                          id={"toggler" + infoBlock.id}
                        >
                          <span className="userHouseInfo--questionSpan">
                            {infoBlock.question}
                            <i className="far fa-caret-square-right userHouseInfo--questionIcon"></i>
                          </span>
                        </Button>
                      </Col>
                    );
                  }
                })}
              </Row>
            ) : (
              <p className="text-center h4">Nincs még megjeleníthető infó.</p>
            )}
          </Col>
        </Row>
      ) : (
        <div className="spinner"></div>
      )}
    </div>
  );
};

export default AdminHouseInfo;
