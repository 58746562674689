import React from "react";
import {
  Card,
  CardText,
  CardHeader,
  CardImg,
  CardTitle,
  CardSubtitle,
  CardFooter,
  ButtonGroup,
  Button,
} from "reactstrap";
import SvgIcon from "@material-ui/core/SvgIcon";

const PlaceCard = ({ place, isAdmin, onEdit, onDelete }) => {
  const locationIcon = (
    <SvgIcon width="1em" height="1em" viewBox="0 0 16 16" className="mr-1">
      <path
        fillRule="evenodd"
        d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
      />
    </SvgIcon>
  );

  return (
    <Card className="places--card" outline color="warning" key={place.id}>
      <CardHeader className="places--card_header">
        <CardTitle className="places--card_title">{place.name}</CardTitle>
        <CardSubtitle className="places--card_subtitle">
          {locationIcon}
          <a href={place.locationLink} target="_blank noopener noreferrer">
            {place.location}
          </a>
        </CardSubtitle>
      </CardHeader>
      <CardImg
        className="places--card_image"
        src={`data:image/png;base64,${place.picture}`}
        alt="Kép az adott helyszről."
      />
      <CardFooter className="places--card_footer">
        <CardText>{place.description}</CardText>
        {isAdmin && (
          <div className="places--card_footer--buttonGroup">
            <ButtonGroup>
              <Button
                type="button"
                size="lg"
                color="warning"
                outline
                onClick={(event) => onEdit(event, place)}
              >
                <i className="far fa-edit"></i>
              </Button>
              <Button
                type="button"
                size="lg"
                color="warning"
                outline
                onClick={(event) => onDelete(event, place.id)}
              >
                <i className="far fa-trash-alt"></i>
              </Button>
            </ButtonGroup>
          </div>
        )}
      </CardFooter>
    </Card>
  );
};

export default PlaceCard;
