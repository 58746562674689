import React from "react";
import {
  CardDeck,
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import PlaceCard from "./PlaceCard";
import {
  puzzleLined,
  puzzleFilled,
  resturantIcon,
  sunLined,
  sunFilled,
  compassLined,
  compassFilled,
} from "../../helpers/iconStorage";

const UserPlaces = (props) => {
  return (
    <div className="places--container">
      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={8} lg={6}>
          <Nav tabs className="places--navContainer">
            <NavItem>
              <NavLink
                active={props.activeTab === "Gyerekes helyek"}
                onClick={() => {
                  props.toggleTab("Gyerekes helyek");
                }}
              >
                <div className="places--navTitleContainer">
                  {props.activeTab === "Gyerekes helyek"
                    ? puzzleFilled
                    : puzzleLined}
                  <span className="places--navTitleSpan">Gyerekes helyek</span>
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={props.activeTab === "Strandok"}
                onClick={() => {
                  props.toggleTab("Strandok");
                }}
              >
                <div className="places--navTitleContainer">
                  {props.activeTab === "Strandok" ? sunFilled : sunLined}
                  <span className="places--navTitleSpan">Strandok</span>
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={props.activeTab === "Éttermek"}
                onClick={() => {
                  props.toggleTab("Éttermek");
                }}
              >
                <div className="places--navTitleContainer">
                  {props.activeTab === "Éttermek"
                    ? resturantIcon
                    : resturantIcon}
                  <span className="places--navTitleSpan">Éttermek</span>
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={props.activeTab === "Kirándulás"}
                onClick={() => {
                  props.toggleTab("Kirándulás");
                }}
              >
                <div className="places--navTitleContainer">
                  {props.activeTab === "Kirándulás"
                    ? compassFilled
                    : compassLined}
                  <span className="places--navTitleSpan">Kirándulás</span>
                </div>
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={8} lg={6}>
          <div className="places--tabContents">
            <TabContent activeTab={props.activeTab}>
              <TabPane tabId="Gyerekes helyek">
                <h2 className="places--tabContentTitle">Gyerekes helyek</h2>
                {props.places ? (
                  <Row className="userHouseInfo--row">
                    {props.gyerekesCardDecks &&
                    props.gyerekesCardDecks.length > 0 ? (
                      <div>
                        {props.gyerekesCardDecks.map((cardDeck) => {
                          return (
                            <CardDeck key={cardDeck.deckIndex} className="mb-5">
                              {cardDeck.placeArray.map((place) => {
                                return (
                                  <PlaceCard
                                    place={place}
                                    isAdmin={false}
                                    key={place.id}
                                  />
                                );
                              })}
                            </CardDeck>
                          );
                        })}
                      </div>
                    ) : (
                      <p className="text-center h4">
                        Nincs még megjeleníthető helyszín ebben a kategóriában.
                      </p>
                    )}
                  </Row>
                ) : (
                  <div className="spinner"></div>
                )}
              </TabPane>
            </TabContent>

            <TabContent activeTab={props.activeTab}>
              <TabPane tabId="Strandok">
                <h2 className="places--tabContentTitle">Strandok</h2>

                {props.places ? (
                  <Row className="userHouseInfo--row">
                    {props.strandCardDecks &&
                    props.strandCardDecks.length > 0 ? (
                      <div>
                        {props.strandCardDecks.map((cardDeck) => {
                          return (
                            <CardDeck key={cardDeck.deckIndex} className="mb-5">
                              {cardDeck.placeArray.map((place) => {
                                return (
                                  <PlaceCard
                                    place={place}
                                    isAdmin={false}
                                    key={place.id}
                                  />
                                );
                              })}
                            </CardDeck>
                          );
                        })}
                      </div>
                    ) : (
                      <p className="text-center h4">
                        Nincs még megjeleníthető helyszín ebben a kategóriában.
                      </p>
                    )}
                  </Row>
                ) : (
                  <div className="spinner"></div>
                )}
              </TabPane>
            </TabContent>

            <TabContent activeTab={props.activeTab}>
              <TabPane tabId="Éttermek">
                <h2 className="places--tabContentTitle">Éttermek</h2>
                {props.places ? (
                  <Row className="userHouseInfo--row">
                    {props.etteremCardDecks &&
                    props.etteremCardDecks.length > 0 ? (
                      <div>
                        {props.etteremCardDecks.map((cardDeck) => {
                          return (
                            <CardDeck key={cardDeck.deckIndex} className="mb-5">
                              {cardDeck.placeArray.map((place) => {
                                return (
                                  <PlaceCard
                                    place={place}
                                    isAdmin={false}
                                    key={place.id}
                                  />
                                );
                              })}
                            </CardDeck>
                          );
                        })}
                      </div>
                    ) : (
                      <p className="text-center h4">
                        Nincs még megjeleníthető helyszín ebben a kategóriában.
                      </p>
                    )}
                  </Row>
                ) : (
                  <div className="spinner"></div>
                )}
              </TabPane>
            </TabContent>

            <TabContent activeTab={props.activeTab}>
              <TabPane tabId="Kirándulás">
                <h2 className="places--tabContentTitle">Kirándulás</h2>
                {props.places ? (
                  <Row className="userHouseInfo--row">
                    {props.kirandulasCardDecks &&
                    props.kirandulasCardDecks.length > 0 ? (
                      <div>
                        {props.kirandulasCardDecks.map((cardDeck) => {
                          return (
                            <CardDeck key={cardDeck.deckIndex} className="mb-5">
                              {cardDeck.placeArray.map((place) => {
                                return (
                                  <PlaceCard
                                    place={place}
                                    isAdmin={false}
                                    key={place.id}
                                  />
                                );
                              })}
                            </CardDeck>
                          );
                        })}
                      </div>
                    ) : (
                      <p className="text-center h4">
                        Nincs még megjeleníthető helyszín ebben a kategóriában.
                      </p>
                    )}
                  </Row>
                ) : (
                  <div className="spinner"></div>
                )}
              </TabPane>
            </TabContent>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UserPlaces;
