import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// don't comment this out in development mode! only when it's ready to be built and  deployed. + change href from style.min.css to style.css in dev mode + npm run watch:sass
// import "./assets/css/style.min.css";
import "./assets/css/style.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
