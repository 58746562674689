import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Badge,
} from "reactstrap";

const LoginStatsModal = (props) => {
  const { modalOpen, toggleStatsModal, loginStatistics, clearStats } = props;

  const closeBtn = <button className="failedModal_closeButton">&times;</button>;

  const dateFormatter = (dateToFormat) => {
    const result = new Date(dateToFormat).toLocaleString("hu-HU").slice(0, -3);
    return result;
  };

  return (
    <div>
      <Modal
        isOpen={modalOpen}
        centered
        className="failedModal_modal"
        toggle={toggleStatsModal}
      >
        <ModalHeader lose={closeBtn} className="mb-3 loginStats_modalHeader">
          Bejelentkezési statisztikák
        </ModalHeader>

        <ModalBody>
          <h3 className="loginStats_text">
            Bejelentkezések száma:{" "}
            <Badge pill color="warning">
              {loginStatistics && loginStatistics.length}{" "}
            </Badge>
          </h3>
          <h4 className="mt-3 loginStats_text">Időpontjai: </h4>
          {loginStatistics ? (
            loginStatistics.length > 0 ? (
              loginStatistics.map((stat) => {
                return (
                  <span key={stat.id} className="loginStats_listItem">
                    {dateFormatter(stat.createdAt)}
                    &#59;&nbsp;&nbsp;
                  </span>
                );
              })
            ) : (
              <span className="loginStats_text">Nincs találat...</span>
            )
          ) : (
            <div className="spinner"></div>
          )}
        </ModalBody>

        <ModalFooter className="border-top-0 mt-4 mb-4 d-flex justify-content-around failedModal failedModal_footer">
          <Button
            onClick={clearStats}
            className="failedModal_button"
            style={{ color: "red !important" }}
          >
            Adatok törlése
          </Button>
          <Button onClick={toggleStatsModal} className="failedModal_button">
            Bezár
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default LoginStatsModal;
