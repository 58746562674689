import { LOGIN_STATS_FETCHED } from '../actions/loginStats';

// eslint-disable-next-line
export default (state = null, action = {}) => {
    switch (action.type) {
        case LOGIN_STATS_FETCHED:
            return action.payload;
        default:
            return state;
    }
};
