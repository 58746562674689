import React from "react";
import {
  Col,
  Row,
  CardDeck,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import PlaceCard from "./PlaceCard";
import {
  puzzleLined,
  puzzleFilled,
  resturantIcon,
  sunLined,
  sunFilled,
  compassLined,
  compassFilled,
} from "../../helpers/iconStorage";

const AdminPlaces = ({
  places,
  newPlace,
  onSubmit,
  onChange,
  deletePlaceOnClick,
  editPlaceOnClick,
  placeToEdit,
  editPlaceOnChange,
  saveEditedPlace,
  cancelEditingPlace,
  activeTab,
  toggleTab,
  gyerekesCardDecks,
  strandCardDecks,
  etteremCardDecks,
  kirandulasCardDecks,
}) => {
  return (
    <div className="places--container">
      {places ? (
        <Row className="userHouseInfo--row">
          <Col xs={12} sm={10} md={10} lg={7}>
            <Form className="mb-5 adminHouseInfo--form" onSubmit={onSubmit}>
              <legend>Új hely hozzáadása</legend>
              <FormGroup>
                <Label for="category">Hely kategóriája</Label>
                <Input
                  type="select"
                  name="category"
                  id="category"
                  value={newPlace.category}
                  onChange={onChange}
                >
                  <option>Gyerekes helyek</option>
                  <option>Strandok</option>
                  <option>Éttermek</option>
                  <option>Kirándulás</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="picture">Kép a helyről</Label>
                <Input
                  type="file"
                  name="picture"
                  id="picture"
                  accept=".jpeg, .png, .jpg"
                  onChange={onChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="name">Hely neve</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Pl. 'Zsuzsa néni háza.'"
                  value={newPlace.name}
                  onChange={onChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="location">Hely címe</Label>
                <Input
                  type="text"
                  name="location"
                  id="location"
                  placeholder="Pl. 'Petőfi Sándor u. 25., Balatonkenese'"
                  value={newPlace.location}
                  onChange={onChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="locationLink">Cím URL</Label>
                <Input
                  type="text"
                  name="locationLink"
                  id="locationLink"
                  placeholder="Google maps URL"
                  value={newPlace.locationLink}
                  onChange={onChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="description">Hely leírása</Label>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  placeholder="Lehet hosszabb szöveg."
                  value={newPlace.description}
                  onChange={onChange}
                />
              </FormGroup>
              <Button
                type="submit"
                color="warning"
                className="adminHouseInfo--formButton"
              >
                Mentés
              </Button>
            </Form>
          </Col>
        </Row>
      ) : (
        <div className="spinner"></div>
      )}

      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={8} lg={6}>
          <Nav tabs className="places--navContainer">
            <NavItem>
              <NavLink
                active={activeTab === "Gyerekes helyek"}
                onClick={() => {
                  toggleTab("Gyerekes helyek");
                }}
              >
                <div className="places--navTitleContainer">
                  {activeTab === "Gyerekes helyek" ? puzzleFilled : puzzleLined}
                  <span className="places--navTitleSpan">Gyerekes helyek</span>
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === "Strandok"}
                onClick={() => {
                  toggleTab("Strandok");
                }}
              >
                <div className="places--navTitleContainer">
                  {activeTab === "Strandok" ? sunFilled : sunLined}
                  <span className="places--navTitleSpan">Strandok</span>
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === "Éttermek"}
                onClick={() => {
                  toggleTab("Éttermek");
                }}
              >
                <div className="places--navTitleContainer">
                  {activeTab === "Éttermek" ? resturantIcon : resturantIcon}
                  <span className="places--navTitleSpan">Éttermek</span>
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === "Kirándulás"}
                onClick={() => {
                  toggleTab("Kirándulás");
                }}
              >
                <div className="places--navTitleContainer">
                  {activeTab === "Kirándulás" ? compassFilled : compassLined}
                  <span className="places--navTitleSpan">Kirándulás</span>
                </div>
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={8} lg={6}>
          <div className="places--tabContents">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="Gyerekes helyek">
                <h2 className="places--tabContentTitle">Gyerekes helyek</h2>
                {places ? (
                  <Row className="userHouseInfo--row">
                    {gyerekesCardDecks && gyerekesCardDecks.length > 0 ? (
                      <div
                        style={{
                          width:
                            gyerekesCardDecks.length === 1 && placeToEdit
                              ? "100%"
                              : "auto",
                        }}
                      >
                        {gyerekesCardDecks.map((cardDeck) => {
                          return (
                            <CardDeck key={cardDeck.deckIndex} className="mb-5">
                              {cardDeck.placeArray.map((place) => {
                                if (
                                  placeToEdit &&
                                  placeToEdit.id === place.id
                                ) {
                                  return (
                                    <Row
                                      key={place.id}
                                      className="places--editModeCardRow"
                                    >
                                      <Col
                                        xs={12}
                                        // sm={10} md={10} lg={7}
                                        // className="card"
                                      >
                                        <Form
                                          className="mb-5 adminHouseInfo--form"
                                          onSubmit={saveEditedPlace}
                                        >
                                          <legend>Hely szerkesztése</legend>
                                          <FormGroup>
                                            <Label for="category">
                                              Hely kategóriája
                                            </Label>
                                            <Input
                                              type="select"
                                              name="category"
                                              id="category"
                                              value={placeToEdit.category}
                                              onChange={editPlaceOnChange}
                                            >
                                              <option>Gyerekes helyek</option>
                                              <option>Strandok</option>
                                              <option>Éttermek</option>
                                              <option>Kirándulás</option>
                                            </Input>
                                          </FormGroup>
                                          <FormGroup>
                                            <Label for="picture">
                                              Kép a helyről
                                            </Label>
                                            <Input
                                              type="file"
                                              name="picture"
                                              id="picture"
                                              accept=".jpeg, .png, .jpg"
                                              onChange={editPlaceOnChange}
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <Label for="name">Hely neve</Label>
                                            <Input
                                              type="text"
                                              name="name"
                                              id="name"
                                              placeholder="Pl. 'Zsuzsa néni háza.'"
                                              value={placeToEdit.name}
                                              onChange={editPlaceOnChange}
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <Label for="location">
                                              Hely címe
                                            </Label>
                                            <Input
                                              type="text"
                                              name="location"
                                              id="location"
                                              placeholder="Pl. 'Petőfi Sándor u. 25., Balatonkenese'"
                                              value={placeToEdit.location}
                                              onChange={editPlaceOnChange}
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <Label for="locationLink">
                                              Cím URL
                                            </Label>
                                            <Input
                                              type="text"
                                              name="locationLink"
                                              id="locationLink"
                                              placeholder="Google maps URL"
                                              value={placeToEdit.locationLink}
                                              onChange={editPlaceOnChange}
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <Label for="description">
                                              Hely leírása
                                            </Label>
                                            <Input
                                              type="textarea"
                                              name="description"
                                              id="description"
                                              placeholder="Lehet hosszabb szöveg."
                                              value={placeToEdit.description}
                                              onChange={editPlaceOnChange}
                                            />
                                          </FormGroup>
                                          <Button
                                            type="submit"
                                            color="warning"
                                            className="adminHouseInfo--formButton"
                                          >
                                            Mentés
                                          </Button>
                                          <Button
                                            type="button"
                                            color="warning"
                                            className="adminHouseInfo--formButton"
                                            onClick={cancelEditingPlace}
                                          >
                                            Mégse
                                          </Button>
                                        </Form>
                                      </Col>
                                    </Row>
                                  );
                                } else {
                                  return (
                                    <PlaceCard
                                      place={place}
                                      isAdmin={true}
                                      key={place.id}
                                      onEdit={editPlaceOnClick}
                                      onDelete={deletePlaceOnClick}
                                    />
                                  );
                                }
                              })}
                            </CardDeck>
                          );
                        })}
                      </div>
                    ) : (
                      <p className="text-center h4">
                        Nincs még megjeleníthető helyszín ebben a kategóriában.
                      </p>
                    )}
                  </Row>
                ) : (
                  <div className="spinner"></div>
                )}
              </TabPane>
            </TabContent>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="Strandok">
                <h2 className="places--tabContentTitle">Strandok</h2>

                {places ? (
                  <Row className="userHouseInfo--row">
                    {strandCardDecks && strandCardDecks.length > 0 ? (
                      <div
                        style={{
                          width:
                            strandCardDecks.length === 1 && placeToEdit
                              ? "100%"
                              : "auto",
                        }}
                      >
                        {strandCardDecks.map((cardDeck) => {
                          return (
                            <CardDeck key={cardDeck.deckIndex} className="mb-5">
                              {cardDeck.placeArray.map((place) => {
                                if (
                                  placeToEdit &&
                                  placeToEdit.id === place.id
                                ) {
                                  return (
                                    <Row
                                      key={place.id}
                                      className="places--editModeCardRow"
                                    >
                                      <Col
                                        xs={12}
                                        // sm={10} md={10} lg={7}
                                      >
                                        <Form
                                          className="mb-5 adminHouseInfo--form"
                                          onSubmit={saveEditedPlace}
                                        >
                                          <legend>Hely szerkesztése</legend>
                                          <FormGroup>
                                            <Label for="category">
                                              Hely kategóriája
                                            </Label>
                                            <Input
                                              type="select"
                                              name="category"
                                              id="category"
                                              value={placeToEdit.category}
                                              onChange={editPlaceOnChange}
                                            >
                                              <option>Gyerekes helyek</option>
                                              <option>Strandok</option>
                                              <option>Éttermek</option>
                                              <option>Kirándulás</option>
                                            </Input>
                                          </FormGroup>
                                          <FormGroup>
                                            <Label for="picture">
                                              Kép a helyről
                                            </Label>
                                            <Input
                                              type="file"
                                              name="picture"
                                              id="picture"
                                              accept=".jpeg, .png, .jpg"
                                              onChange={editPlaceOnChange}
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <Label for="name">Hely neve</Label>
                                            <Input
                                              type="text"
                                              name="name"
                                              id="name"
                                              placeholder="Pl. 'Zsuzsa néni háza.'"
                                              value={placeToEdit.name}
                                              onChange={editPlaceOnChange}
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <Label for="location">
                                              Hely címe
                                            </Label>
                                            <Input
                                              type="text"
                                              name="location"
                                              id="location"
                                              placeholder="Pl. 'Petőfi Sándor u. 25., Balatonkenese'"
                                              value={placeToEdit.location}
                                              onChange={editPlaceOnChange}
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <Label for="locationLink">
                                              Cím URL
                                            </Label>
                                            <Input
                                              type="text"
                                              name="locationLink"
                                              id="locationLink"
                                              placeholder="Google maps URL"
                                              value={placeToEdit.locationLink}
                                              onChange={editPlaceOnChange}
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <Label for="description">
                                              Hely leírása
                                            </Label>
                                            <Input
                                              type="textarea"
                                              name="description"
                                              id="description"
                                              placeholder="Lehet hosszabb szöveg."
                                              value={placeToEdit.description}
                                              onChange={editPlaceOnChange}
                                            />
                                          </FormGroup>
                                          <Button
                                            type="submit"
                                            color="warning"
                                            className="adminHouseInfo--formButton"
                                          >
                                            Mentés
                                          </Button>
                                          <Button
                                            type="button"
                                            color="warning"
                                            className="adminHouseInfo--formButton"
                                            onClick={cancelEditingPlace}
                                          >
                                            Mégse
                                          </Button>
                                        </Form>
                                      </Col>
                                    </Row>
                                  );
                                } else {
                                  return (
                                    <PlaceCard
                                      place={place}
                                      isAdmin={true}
                                      key={place.id}
                                      onEdit={editPlaceOnClick}
                                      onDelete={deletePlaceOnClick}
                                    />
                                  );
                                }
                              })}
                            </CardDeck>
                          );
                        })}
                      </div>
                    ) : (
                      <p className="text-center h4">
                        Nincs még megjeleníthető helyszín ebben a kategóriában.
                      </p>
                    )}
                  </Row>
                ) : (
                  <div className="spinner"></div>
                )}
              </TabPane>
            </TabContent>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="Éttermek">
                <h2 className="places--tabContentTitle">Éttermek</h2>
                {places ? (
                  <Row className="userHouseInfo--row">
                    {etteremCardDecks && etteremCardDecks.length > 0 ? (
                      <div
                        style={{
                          width:
                            etteremCardDecks.length === 1 && placeToEdit
                              ? "100%"
                              : "auto",
                        }}
                      >
                        {etteremCardDecks.map((cardDeck) => {
                          return (
                            <CardDeck key={cardDeck.deckIndex} className="mb-5">
                              {cardDeck.placeArray.map((place) => {
                                if (
                                  placeToEdit &&
                                  placeToEdit.id === place.id
                                ) {
                                  return (
                                    <Row
                                      key={place.id}
                                      className="places--editModeCardRow"
                                    >
                                      <Col
                                        xs={12}
                                        // sm={10} md={10} lg={7}
                                      >
                                        <Form
                                          className="mb-5 adminHouseInfo--form"
                                          onSubmit={saveEditedPlace}
                                        >
                                          <legend>Hely szerkesztése</legend>
                                          <FormGroup>
                                            <Label for="category">
                                              Hely kategóriája
                                            </Label>
                                            <Input
                                              type="select"
                                              name="category"
                                              id="category"
                                              value={placeToEdit.category}
                                              onChange={editPlaceOnChange}
                                            >
                                              <option>Gyerekes helyek</option>
                                              <option>Strandok</option>
                                              <option>Éttermek</option>
                                              <option>Kirándulás</option>
                                            </Input>
                                          </FormGroup>
                                          <FormGroup>
                                            <Label for="picture">
                                              Kép a helyről
                                            </Label>
                                            <Input
                                              type="file"
                                              name="picture"
                                              id="picture"
                                              accept=".jpeg, .png, .jpg"
                                              onChange={editPlaceOnChange}
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <Label for="name">Hely neve</Label>
                                            <Input
                                              type="text"
                                              name="name"
                                              id="name"
                                              placeholder="Pl. 'Zsuzsa néni háza.'"
                                              value={placeToEdit.name}
                                              onChange={editPlaceOnChange}
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <Label for="location">
                                              Hely címe
                                            </Label>
                                            <Input
                                              type="text"
                                              name="location"
                                              id="location"
                                              placeholder="Pl. 'Petőfi Sándor u. 25., Balatonkenese'"
                                              value={placeToEdit.location}
                                              onChange={editPlaceOnChange}
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <Label for="locationLink">
                                              Cím URL
                                            </Label>
                                            <Input
                                              type="text"
                                              name="locationLink"
                                              id="locationLink"
                                              placeholder="Google maps URL"
                                              value={placeToEdit.locationLink}
                                              onChange={editPlaceOnChange}
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <Label for="description">
                                              Hely leírása
                                            </Label>
                                            <Input
                                              type="textarea"
                                              name="description"
                                              id="description"
                                              placeholder="Lehet hosszabb szöveg."
                                              value={placeToEdit.description}
                                              onChange={editPlaceOnChange}
                                            />
                                          </FormGroup>
                                          <Button
                                            type="submit"
                                            color="warning"
                                            className="adminHouseInfo--formButton"
                                          >
                                            Mentés
                                          </Button>
                                          <Button
                                            type="button"
                                            color="warning"
                                            className="adminHouseInfo--formButton"
                                            onClick={cancelEditingPlace}
                                          >
                                            Mégse
                                          </Button>
                                        </Form>
                                      </Col>
                                    </Row>
                                  );
                                } else {
                                  return (
                                    <PlaceCard
                                      place={place}
                                      isAdmin={true}
                                      key={place.id}
                                      onEdit={editPlaceOnClick}
                                      onDelete={deletePlaceOnClick}
                                    />
                                  );
                                }
                              })}
                            </CardDeck>
                          );
                        })}
                      </div>
                    ) : (
                      <p className="text-center h4">
                        Nincs még megjeleníthető helyszín ebben a kategóriában.
                      </p>
                    )}
                  </Row>
                ) : (
                  <div className="spinner"></div>
                )}
              </TabPane>
            </TabContent>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="Kirándulás">
                <h2 className="places--tabContentTitle">Kirándulás</h2>
                {places ? (
                  <Row className="userHouseInfo--row">
                    {kirandulasCardDecks && kirandulasCardDecks.length > 0 ? (
                      <div
                        style={{
                          width:
                            kirandulasCardDecks.length === 1 && placeToEdit
                              ? "100%"
                              : "auto",
                        }}
                      >
                        {kirandulasCardDecks.map((cardDeck) => {
                          return (
                            <CardDeck key={cardDeck.deckIndex} className="mb-5">
                              {cardDeck.placeArray.map((place) => {
                                if (
                                  placeToEdit &&
                                  placeToEdit.id === place.id
                                ) {
                                  return (
                                    <Row
                                      key={place.id}
                                      className="places--editModeCardRow"
                                    >
                                      <Col
                                        xs={12}
                                        // sm={10} md={10} lg={7}
                                      >
                                        <Form
                                          className="mb-5 adminHouseInfo--form"
                                          onSubmit={saveEditedPlace}
                                        >
                                          <legend>Hely szerkesztése</legend>
                                          <FormGroup>
                                            <Label for="category">
                                              Hely kategóriája
                                            </Label>
                                            <Input
                                              type="select"
                                              name="category"
                                              id="category"
                                              value={placeToEdit.category}
                                              onChange={editPlaceOnChange}
                                            >
                                              <option>Gyerekes helyek</option>
                                              <option>Strandok</option>
                                              <option>Éttermek</option>
                                              <option>Kirándulás</option>
                                            </Input>
                                          </FormGroup>
                                          <FormGroup>
                                            <Label for="picture">
                                              Kép a helyről
                                            </Label>
                                            <Input
                                              type="file"
                                              name="picture"
                                              id="picture"
                                              accept=".jpeg, .png, .jpg"
                                              onChange={editPlaceOnChange}
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <Label for="name">Hely neve</Label>
                                            <Input
                                              type="text"
                                              name="name"
                                              id="name"
                                              placeholder="Pl. 'Zsuzsa néni háza.'"
                                              value={placeToEdit.name}
                                              onChange={editPlaceOnChange}
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <Label for="location">
                                              Hely címe
                                            </Label>
                                            <Input
                                              type="text"
                                              name="location"
                                              id="location"
                                              placeholder="Pl. 'Petőfi Sándor u. 25., Balatonkenese'"
                                              value={placeToEdit.location}
                                              onChange={editPlaceOnChange}
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <Label for="locationLink">
                                              Cím URL
                                            </Label>
                                            <Input
                                              type="text"
                                              name="locationLink"
                                              id="locationLink"
                                              placeholder="Google maps URL"
                                              value={placeToEdit.locationLink}
                                              onChange={editPlaceOnChange}
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <Label for="description">
                                              Hely leírása
                                            </Label>
                                            <Input
                                              type="textarea"
                                              name="description"
                                              id="description"
                                              placeholder="Lehet hosszabb szöveg."
                                              value={placeToEdit.description}
                                              onChange={editPlaceOnChange}
                                            />
                                          </FormGroup>
                                          <Button
                                            type="submit"
                                            color="warning"
                                            className="adminHouseInfo--formButton"
                                          >
                                            Mentés
                                          </Button>
                                          <Button
                                            type="button"
                                            color="warning"
                                            className="adminHouseInfo--formButton"
                                            onClick={cancelEditingPlace}
                                          >
                                            Mégse
                                          </Button>
                                        </Form>
                                      </Col>
                                    </Row>
                                  );
                                } else {
                                  return (
                                    <PlaceCard
                                      place={place}
                                      isAdmin={true}
                                      key={place.id}
                                      onEdit={editPlaceOnClick}
                                      onDelete={deletePlaceOnClick}
                                    />
                                  );
                                }
                              })}
                            </CardDeck>
                          );
                        })}
                      </div>
                    ) : (
                      <p className="text-center h4">
                        Nincs még megjeleníthető helyszín ebben a kategóriában.
                      </p>
                    )}
                  </Row>
                ) : (
                  <div className="spinner"></div>
                )}
              </TabPane>
            </TabContent>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AdminPlaces;
